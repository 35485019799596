let host = window.location.host.split(':')[0];
let api_endpoint = "http://" + host + ":8000/customer/"
let home_page = "http://" + host + "/"
let system = "develop"

if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_SYSTEM === 'demo' || host === 'customer-demo.zebrain.se') {
        api_endpoint = 'https://api-demo.zebrain.se/customer/'
        home_page = "https://demo.zebrain.se/"
        system = "demo"
    } else if (process.env.REACT_APP_SYSTEM === 'stage') {
        api_endpoint = 'https://api-stage.zebrain.se/customer/'
        home_page = "https://stage.zebrain.se/"
        system = "stage"
    } else {
        api_endpoint = 'https://api.zebrain.se/customer/'
        home_page = "https://www.zebrain.se/"
        system = "prod"
    }
}

export interface AppConfig {
    API_ENDPOINT: string,
    VERSION: string,
    NAME: string,
    TOKEN_NAME: string,
    SESSION_DATA: string,
    ENV: string,
    HOME_PAGE: string,
    SYSTEM: string,
}

export const CONFIG: AppConfig = {
    API_ENDPOINT : api_endpoint,
    VERSION : "1.0.0",
    NAME : "ZebrainCustomer",
    TOKEN_NAME : 'zebrain_customer_token',
    SESSION_DATA: 'zebrain_customer_session',
    ENV: process.env.NODE_ENV,
    HOME_PAGE: home_page,
    SYSTEM: system
};
